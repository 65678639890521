import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { signOut } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';


const SignIn = ({ onSignIn, darkMode, errorMessage }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    e.target.value = newEmail;
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    e.target.value = newPassword;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    
    try {
      // First try to sign out any existing session
      try {
        await signOut({ global: true });
      } catch (signOutError) {
        console.log('No existing session to sign out');
      }

      // Now proceed with sign in
      await onSignIn(email, password);
      navigate('/geojson');
    } catch (error) {
      console.error('Sign in error:', error);
    } finally {
    
    }
  };

  return (
    <div className={`flex items-center justify-center min-h-screen bg-gradient-to-br ${darkMode ? 'from-gray-900 to-gray-700' : 'from-blue-400 to-purple-500'}`}>
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <div className={`px-8 py-6 text-left bg-white dark:bg-gray-800 shadow-2xl rounded-xl`}>
          <h2 className="text-3xl font-extrabold text-center text-gray-900 dark:text-white mb-6">
            Dynamic Planning Dashboard
          </h2>
          <h3 className="text-xl font-semibold text-center text-gray-700 dark:text-gray-300 mb-8">Sign in to your account</h3>
          {errorMessage && (
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded"
          >
            {errorMessage}
          </motion.div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300" htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="you@example.com"
                  className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900 dark:text-white"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300" htmlFor="password">Password</label>
                <input
                  type="password"
                  placeholder="••••••••"
                  className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-900 dark:text-white"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
            </div>
            <div className="mt-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit"
              >
                Sign In
              </motion.button>
            </div>
          </form>
          {/* <div className="mt-6 text-center">
            <a href="#" className="text-sm text-indigo-600 hover:text-indigo-500">Forgot your password?</a>
          </div> */}
        </div>
      </motion.div>
    </div>
  );
};

export default SignIn;